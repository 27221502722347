<template>
  <div class="container">
    <div class="heading-background" />
    <div class="row center-xs">
      <div class="col-xs-12 col-sm-10 col-lg-9">
        <div>
          <h1 class="center-xs hidden-xs main-header">
            {{ $t("Thank you!") }}
          </h1>
          <h1 class="center-xs mt30 visible-xs main-header ">
            {{ $t("Thank you!") }}
          </h1>
          <p class="center-xs my25 paragraph-bold">
            {{ $t("You have submitted {count} item(s) for verification.", {count: getAddedProductsCount}) }}
          </p>
          <p class="center-xs paragraph header-details">
            {{ $t("It takes up to 2 business days to complete the 1st stage of verification. You will receive an e-mail once the verification is completed.") }}
          </p>
          <h1 class="center-xs header-big-bold">
            {{ $t("What happens next?") }}
          </h1>
          <div class="row">
            <div class="box-wrapper col-xs-12 col-sm-6 col-md-6 col-lg-4" v-for="(stage, index) in stages" :key="stage.heading">
              <div class="box">
                <div class="box-content">
                  <div class="center-xs stage-number">
                    <p>{{ index + 1 }}</p>
                  </div>
                  <p class="center-xs stage-header">
                    {{ $t(stage.heading) }}
                  </p>
                  <p class="center-xs mb20 paragraph">
                    {{ $t(stage.description) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="box-wrapper col-xs-12 col-sm-6 col-md-6 col-lg-4">
              <div class="contact-box">
                <div>
                  <h1 class="center-xs header-big">
                    {{ $t("Have more questions?") }}
                  </h1>
                  <p class="center-xs paragraph">
                    {{ $t("Call us") }}<br>+48 698 136 499
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="center-xs mt25 mb35">
            <button-outline class="button-outline" @click.native="changePage">
              {{ $t("Explore") }}
            </button-outline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonOutline from 'src/themes/theme-egarderobe/components/theme/ButtonOutline.vue'
import { mapGetters } from 'vuex'
import config from 'config'

export default {
  name: 'ThankYouForSellingPage',
  components: {
    ButtonOutline
  },
  data () {
    return {
      stages: [
        {
          heading: 'Prepare your items',
          description: 'Make sure that all products are clean without any stains or odors before delivery.'
        },
        {
          heading: 'Delivery',
          description: 'You can ship or drop off your pre-loved products. We will send you a pre-paid shipping label which means just to print, pack and drop-off.'
        },
        {
          heading: '2nd verification',
          description: 'After receiving your products our professional authenticator will verify your items on its originality and quality.'
        },
        {
          heading: 'We make it easy',
          description: 'We are here to sell your products fast and take care of every step of the way'
        },
        {
          heading: 'Get paid',
          description: 'Extend life of luxury reselling items you no longer wear.'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getAddedProductsCount: 'selling/getAddedProductsCount',
      getMenuCategories: 'category-next/getMenuCategories'
    }),
    getMainCategory () {
      return this.getMenuCategories.find(cat => cat.id === config.server.categoryIdsMap.mainCategoryId)
    }
  },
  methods: {
    changePage () {
      this.$router.push(this.localizedRoute(this.getMainCategory.url_path + '?sort=id%3Adesc'))
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-white: color(white);
  $color-black: color(black);

  .main-header {
    margin-top: 100px;
    letter-spacing: 2.73px;
    text-transform: uppercase;
    font-size: 31px;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 15px;
      letter-spacing: 1.32px;
    }
    @media (max-width: 914px) {
      margin-top: 40px;
    }
  }
  .header-big {
    letter-spacing: 1.58px;
    font-weight: 500;
    font-size: 24px;
    @media (max-width: 768px) {
      font-size: 18px;
      letter-spacing: 1.32px;
    }
  }
  .header-big-bold {
    font-family: 'Poppins';
    font-size: 18px;
    letter-spacing: 1.58px;
    margin: 35px 0;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .paragraph {
    font-size: 12px;
  }
  .paragraph-bold {
    font-size: 12px;
    font-weight: bold;
  }
  .stage-number {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    font-size: 12px;
    margin: auto;
    border-radius: 50%;
    color: $color-white;
    background-color: $color-black;
  }
  .stage-header {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.06px;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .button-outline {
    padding-top: 15px;
    padding-bottom: 15px;
    @media (max-width: 768px) {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 12px;
    }
  }
  .box-wrapper {
    padding: 0 10px 20px 10px;
    @media (max-width: 576px) {
      padding: 0 30px 20px 30px;
    }
  }
  .box {
    border: 1px solid #D9D9D9;
    height: 100%;
    background-color: #ffffff;
  }
  .box-content {
    margin: auto;
    padding: 50px;
    max-width: 350px;
    max-height: 300px;
    min-height: 200px;
  }
  .contact-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 300px;
    border: 1px solid #D9D9D9;
  }
  .heading-background {
    position: absolute;
    top: 134px;
    right: 0;
    left: 0;
    z-index: -1;
    height: 470px;
    background-image: url(/assets/girl-with-boxes.jpg);
    background-position-x: 50%;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1140px) {
      background-position-x: 40%;
    }
    @media (max-width: 992px) {
      background-image: unset;
      background-position-x: unset;
      background-position-y: unset;
      background-repeat: unset;
      background-size: unset;
      background-color: #e9edf2;
    }
  }
  .header-details {
    max-width: 417px;
    min-width: 417px;
    margin: auto;
    @media (max-width: 767px) {
      min-width: none;
    }
  }
</style>
